@keyframes pulsating-background {
  from {
    background: var(--chakra-colors-blue-400);
  }
  to {
    background: var(--chakra-colors-blue-700);
  }
}

.app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  color: white;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-blue:hover {
  color: var(--chakra-colors-blue-400);
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  user-select: none;
}

.list-group-menu-item:hover {
  background: var(--chakra-colors-blue-400);
  color: white;
}

.empty-list, .menu-item-disabled {
  color: #555555;
}

.menu-item-disabled {
  cursor: default;
}

.player-list {
  color: black;
  min-width: 150px;
}

.popover-card {
  color: black;
  min-width: 100px;
}

.card-header {
  background-color: var(--chakra-colors-blue-400);
  color: white;
  cursor: default;
}

.menu-item-disabled {
  cursor: default;
}

.game-settings {
  border: 4px solid var(--chakra-colors-blue-400) !important;
}

.game-starting {
  padding: 100px 30px;
}

.color-sample {
  border: 1px solid #E2E8F0;
  height: 50px;
}

.color-sample:hover, .color-sample-selected {
  border: 3px solid black;
}

.logo-page {
  color: black;
  width: 90%;
}

.logo-page-content {
  /*margin-top: 150px;*/
  width: 100%;
}

.logo {
  color: white;
  cursor: default;
  font-family: "Alfa Slab One", serif !important;
  font-weight: bolder !important;
  font-size: 80px !important;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
}

.room-code {
  font-size: 40px;
  font-weight: bolder;
}

.unselected-clue:hover {
  background-color: var(--chakra-colors-gray-500);
}

button[role=tab]:focus {
  box-shadow: none;
  outline: none;
}

button[role=tab][aria-selected=true] {
  color: var(--chakra-colors-blue-400);
  font-weight: bold;
}

.animate-pulsating-background {
  animation-name: pulsating-background;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.chakra-checkbox__label[data-disabled] {
  opacity: 1 !important;
}
